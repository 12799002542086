<template>
  <content-container
    :title="title"
    :subtitle="subtitle"
    :description="description">
    <video
      :src="video"
      v-if="video"
      class="video"
      controls
    />
  </content-container>
</template>

<script>
  import ContentMixin from '../../mixin/content'

  export default {
    inheritAttrs: false,
    props: [
      'category',
      'breadcrumb',
      'splitviewMode',
    ],
    computed: {
      video() {
        return this.category?.media?.links[0]?.href
      },
    },
    mixins: [
      ContentMixin,
    ],
    inject: [
      '$helper'
    ],
  }
</script>

<style lang="scss" scoped>
  .video {
    @apply
    w-full
    max-w-full
    max-h-full;
  }
</style>