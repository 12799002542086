export default {
  props: [
    'category',
  ],
  computed: {
    title() {
      return this.$helper.findAttributeValue(this.category, 'Heading')
    },
    subtitle() {
      return this.$helper.findAttributeValue(this.category, 'Subtitle')
    },
    description() {
      return this.$helper.findAttributeValue(this.category, 'Description')
    }
  },
  inject: [
    '$helper'
  ],
}